import React from "react"
import {
    Nav,
    Layout,  
    NewsDisplay   
} from '../app/components'
import { NAVIGATION_ITEMS } from "../app/constants/nav"
import { navigate } from 'gatsby';

export default (props) => {
    let nav = NAVIGATION_ITEMS    
    nav.rigthItems = NAVIGATION_ITEMS.rigthItems.map(item =>{       
      if (item.text === "Tu cuenta" ) {
        item.onclick = () => navigate('/profile')
      } 
      return item
    })   
    return(
        <Layout location={props.location}>             
            <Nav {...nav} scrolled={true} />       
    { props.location.state && <NewsDisplay news={props.location.state.data} /> }
        </Layout>
    )
}